export const nav = {
    marginTop: "1rem",
}

export const navList = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    listStyle: "none",
    padding: 0,
};
